<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-card>
      <b-row>
        <table class="table">
          <thead>
            <th>
              Project Details
            </th>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="customerRules">
        <b-form>
          <b-row class="mt-2">
            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group>
                <label for="firstName">Name*</label>
                <validation-provider #default="{ errors }" rules="required">
                  <b-form-input id="firstName" v-model="projectData.name" :state="errors.length > 0 ? false : null" type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group>
                <label for="client">Client</label>
                <v-select v-model="projectData.clientId" :clearable="false" label="name" :options="listCustomers" :reduce="(option) => option.id" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-if="id == null" type="submit" @click.prevent="formSubmitted" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="formSubmitted" v-show="projectData.archived == false" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BForm, BCard, BContainer, BFormFile, BTabs, BTab, BBadge, BListGroupItem, BListGroup, BNavItem, BFormTextarea, BFormRadioGroup, BInputGroup, BFormRadio, BAlert, BButton, BOverlay, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import vSelect from 'vue-select';
import { onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import VueNumericInput from 'vue-numeric-input';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { required } from '@validations';
import router from '@/router';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import Chart from 'chart.js';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  components: {
    BCard,
    BForm,
    Cleave,

    BContainer,
    BFormFile,
    BTabs,
    BTab,
    flatPickr,
    BBadge,
    BListGroupItem,
    BListGroup,
    BNavItem,
    BCardActions,
    BFormTextarea,
    BFormRadioGroup,
    BInputGroup,
    BFormRadio,
    BAlert,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    ToastificationContent,
    VueNumericInput,
    Treeselect,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },

  computed: {},

  created() {
    this.getByIdProject();
  },

  data() {
    var projectDataModel = {
      name: null,
      clientId: null,
      archived: false,
    };

    return {
      projectsList: [],
      listCustomers: [],

      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      id: null,
      formShow: false,
      projectData: projectDataModel,
      required,
    };
  },

  mounted() {},

  methods: {
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('DD/MM/YYYY hh:mm');
      }
    },

    formSubmitted() {
      this.formShow = true;

      this.$refs.customerRules.validate().then((success) => {
        if (success) {
          if (router.currentRoute.params.id) {
            axiosIns
              .put('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + router.currentRoute.params.id, this.projectData, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
                },
              })
              .then((response) => {
                this.formShow = false;
              })
              .catch((error) => {
                console.log(error);
                this.formShow = false;
              });
          } else {
            var tempData = {
              name: this.projectData.name,
              clientId: this.projectData.clientId,
            };

            axiosIns
              .post('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects', tempData, {
                headers: {
                  'Content-Type': 'application/json',
                  'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
                },
              })
              .then((response) => {
                if (response.status == 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Customer Insert Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });
                  // this.getByIdProject(response.data.id);
                  this.formShow = false;

                  if (router.currentRoute.name == 'contact-add-purchase') {
                    // router.push({ name: 'quote-add', params: { id: router.currentRoute.params.quoteid } });
                  } else {
                    // router.push({ name: 'contact-edit', params: { id: response.data.id } });
                  }
                }
              });
          }
        } else {
          this.formShow = false;
        }
      });
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    convertToISO(dateString) {
      const parts = dateString.split('-');
      const isoDate = new Date(parts[2], parts[1] - 1, parts[0]).toISOString();
      return isoDate;
    },

    hoursFormat(val) {
      var totalMinutes = Math.floor(val / 60);

      var hours = Math.floor(totalMinutes / 60);
      var minutes = totalMinutes % 60;

      return hours + ' Hours, ' + minutes + ' Minutes';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getByIdProject() {
      this.formShow = true;

      var tempData = {
        'page-size': 5000,
        archived: false,
      };

      axiosIns
        .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/clients', {
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
          },
          params: tempData,
        })
        .then((response) => {
          this.listCustomers = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });

      if (router.currentRoute.params.id) {
        this.id = router.currentRoute.params.id;

        axiosIns
          .get('https://api.clockify.me/api/v1/workspaces/600ab5ea69564b6c1564e1fe/projects/' + router.currentRoute.params.id, {
            headers: {
              'Content-Type': 'application/json',
              'X-Api-Key': 'OGM5M2IyY2EtNGJlYS00ZGZiLWEwZGEtODMxNWUwN2RmYWNk',
            },
          })
          .then((response) => {
            // response.data.balanceTable = response.data.balanceTable.reverse();
            this.projectData = response.data;

            this.formShow = false;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                text: 'Please try again or report an issue to support',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
            // router.push({ name: 'quote-list' });
            this.formShow = false;
          });

        this.formShow = false;
      } else {
        this.formShow = false;
      }
    },

    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },

    validationForm1() {
      return new Promise((resolve, reject) => {
        this.$refs.form1.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.user-badge {
  width: 50%;
  align-content: center;
}

.dataRange {
  width: 90%;
  padding: 0.375rem 0rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  text-align: center;
}
</style>
